import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getMetrics,
  getInProgress,
  getAttendantMetrics,
  getIsAwaiting,
  getQueueMetrics
} from "../../services/Ticket";
import {
  Types,
  getAttendantMetricsSuccess,
  getInProgressSuccess,
  getIsAwaitingSuccess,
  getMetricsSuccess,
  getQueueMetricsSuccess,
  setLoadingMetrics,
  setLoadingReports
} from "../reducers/Ticket"
import { errorMessage } from "../actions/FeedbackMessage"

export function* getMetricsSaga() {
    yield takeLatest(Types.GET_METRICS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingMetrics(true))
            const metrics = yield call(getMetrics, filter)
            yield put(getMetricsSuccess(metrics))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingMetrics(false))
        }
    })
}

export function* getQueueMetricsSaga() {
    yield takeLatest(Types.GET_QUEUE_METRICS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const metrics = yield call(getQueueMetrics, filter)
            yield put(getQueueMetricsSuccess(metrics))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

export function* getAttendantMetricsSaga() {
    yield takeLatest(Types.GET_ATTENDANT_METRICS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const metrics = yield call(getAttendantMetrics, filter)
            yield put(getAttendantMetricsSuccess(metrics))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

export function* getInProgressSaga() {
    yield takeLatest(Types.GET_IN_PROGRESS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const tickets = yield call(getInProgress, filter)
            yield put(getInProgressSuccess(tickets))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

export function* getIsAwaitingSaga() {
    yield takeLatest(Types.GET_IS_AWAITING, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const tickets = yield call(getIsAwaiting, filter)
            yield put(getIsAwaitingSuccess(tickets))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getMetricsSaga),
        fork(getQueueMetricsSaga),
        fork(getAttendantMetricsSaga),
        fork(getInProgressSaga),
        fork(getIsAwaitingSaga)
    ]);
}
