import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Ticket {
    static async getMetrics(filters) {
        try {
            const response = await Api.post('/tickets/metrics', filters);

            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async getQueueMetrics(filters) {
        try {
            const response = await Api.post('/tickets/metrics/queue', filters);

            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async getAttendantMetrics(filters) {
        try {
            const response = await Api.post('/tickets/metrics/attendant', filters);

            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async getInProgress(filters) {
        try {
            const response = await Api.post('/tickets/in-progress', filters);

            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async getIsAwaiting(filters) {
        try {
            const response = await Api.post('/tickets/is-awaiting', filters);

            return response;
        } catch (err) {
            throw handleError(err);
        }
    }
}

export const getMetrics = async (filters) => await Ticket.getMetrics(filters);
export const getQueueMetrics = async (filters) => await Ticket.getQueueMetrics(filters);
export const getAttendantMetrics = async (filters) => await Ticket.getAttendantMetrics(filters);
export const getInProgress = async (filters) => await Ticket.getInProgress(filters);
export const getIsAwaiting = async (filters) => await Ticket.getIsAwaiting(filters);

export default Ticket;
