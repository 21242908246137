import {
    SET_FILTER_DASHBOARD,
    SET_FILTER_EVENTS,
    SET_FILTER_UNDERSTANDING,
    SET_FILTER_BOT,
    SET_FILTER_CHANNEL,
    SET_FILTER_ITEM
} from "../../constants/ActionTypes";
import moment from 'moment';

const INIT_STATE = {
    dateEndDashboard: moment().toISOString(),
    dateInitDashboard: moment().subtract(30, 'days').toISOString(),
    statusFiltroDashboard: "30",
    dateEndEvents: moment().toISOString(),
    dateInitEvents: moment().subtract(30, 'days').toISOString(),
    statusFiltroEvents: "30",
    dateEndUnderstanding: moment().toISOString(),
    dateInitUnderstanding: moment().subtract(30, 'days').toISOString(),
    statusFiltroUnderstanding: "30",
    botName:null,
    channelId:null,
    queueId: null,
    companyId: null,
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case SET_FILTER_DASHBOARD: {
            return {
                ...state,
                dateInitDashboard: action.payload.dateInitDashboard,
                dateEndDashboard: action.payload.dateEndDashboard,
                statusFiltroDashboard: action.payload.statusFiltroDashboard,
            }
        }
        case SET_FILTER_EVENTS: {
            return {
                ...state,
                dateInitEvents: action.payload.dateInitEvents,
                dateEndEvents: action.payload.dateEndEvents,
                statusFiltroEvents: action.payload.statusFiltroEvents,
            }
        }
        case SET_FILTER_UNDERSTANDING: {
            return {
                ...state,
                dateInitUnderstanding: action.payload.dateInitUnderstanding,
                dateEndUnderstanding: action.payload.dateEndUnderstanding,
                statusFiltroUnderstanding: action.payload.statusFiltroUnderstanding,
            }
        }
        case SET_FILTER_BOT: {
            return {
                ...state,
                botName: action.payload
            }
        }
        case SET_FILTER_CHANNEL: {
            return {
                ...state,
                channelId: action.payload
            }
        }
        case SET_FILTER_ITEM: {
            // If the item is not in the state, don't change the state
            if(!(action.payload.item in state)) {
                return state;
            }

            return {
                ...state,
                [action.payload.item]: action.payload.value
            }
        }
        default:
            return state;
    }
}
