// Action Types
export const Types = {
    LIST_QUEUE: 'LIST_QUEUE',
    LIST_QUEUE_SUCCESS: 'LIST_TEMPLATES_SUCCESS'
}

// Reducer
const INITIAL_STATE = {
    queues: []
}

export default function reducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case Types.LIST_QUEUE_SUCCESS: {
            return {
                ...state,
                queues: action.payload
            }
        }
        default:
            return state;
    }
}

// Action Creators
export const listQueues = () => {
    return {
        type: Types.LIST_QUEUE
    }
}

export const listQueuesSuccess = (queues) => {
    return {
        type: Types.LIST_QUEUE_SUCCESS,
        payload: queues
    }
}
