// Action Types
export const Types = {
    GET_METRICS: 'GET_METRICS',
    GET_METRICS_SUCCESS: 'GET_METRICS_SUCCESS',
    GET_QUEUE_METRICS: 'GET_QUEUE_METRICS',
    GET_QUEUE_METRICS_SUCCESS: 'GET_QUEUE_METRICS_SUCCESS',
    GET_ATTENDANT_METRICS: 'GET_ATTENDANT_METRICS',
    GET_ATTENDANT_METRICS_SUCCESS: 'GET_ATTENDANT_METRICS_SUCCESS',
    GET_IN_PROGRESS: 'GET_IN_PROGRESS',
    GET_IN_PROGRESS_SUCCESS: 'GET_IN_PROGRESS_SUCCESS',
    GET_IS_AWAITING: 'GET_IS_AWAITING',
    GET_IS_AWAITING_SUCCESS: 'GET_IS_AWAITING_SUCCESS',
    SET_LOADING_METRICS: 'SET_LOADING_METRICS',
    SET_LOADING_REPORTS: 'SET_LOADING_REPORTS'
}

// Reducer
const INITIAL_STATE = {
    metrics: {},
    inProgressTickets: [],
    isAwaitingTickets: [],
    queueMetrics: [],
    attendantMetrics: [],
    isLoadingMetrics: false,
    isLoadingReports: false
}

export default function reducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case Types.GET_METRICS_SUCCESS: {
            return {
                ...state,
                metrics: action.payload
            }
        }
        case Types.GET_QUEUE_METRICS_SUCCESS: {
            return {
                ...state,
                queueMetrics: action.payload
            }
        }
        case Types.GET_ATTENDANT_METRICS_SUCCESS: {
            return {
                ...state,
                attendantMetrics: action.payload
            }
        }
        case Types.GET_IN_PROGRESS_SUCCESS: {
            return {
                ...state,
                inProgressTickets: action.payload
            }
        }
        case Types.GET_IS_AWAITING_SUCCESS: {
            return {
                ...state,
                isAwaitingTickets: action.payload
            }
        }
        case Types.SET_LOADING_METRICS: {
            return {
                ...state,
                isLoadingMetrics: action.payload
            }
        }
        case Types.SET_LOADING_REPORTS: {
            return {
                ...state,
                isLoadingReports: action.payload
            }
        }
        default:
            return state;
    }
}

// Action Creators
export const getMetrics = (filter) => {
    return {
        type: Types.GET_METRICS,
        payload: filter
    }
}

export const getMetricsSuccess = (metrics) => {
    return {
        type: Types.GET_METRICS_SUCCESS,
        payload: metrics
    }
}

export const getQueueMetrics = (filter) => {
    return {
        type: Types.GET_QUEUE_METRICS,
        payload: filter
    }
}

export const getQueueMetricsSuccess = (metrics) => {
    return {
        type: Types.GET_QUEUE_METRICS_SUCCESS,
        payload: metrics
    }
}

export const getAttendantMetrics = (filter) => {
    return {
        type: Types.GET_ATTENDANT_METRICS,
        payload: filter
    }
}

export const getAttendantMetricsSuccess = (metrics) => {
    return {
        type: Types.GET_ATTENDANT_METRICS_SUCCESS,
        payload: metrics
    }
}

export const getInProgress = (filter) => {
    return {
        type: Types.GET_IN_PROGRESS,
        payload: filter
    }
}

export const getInProgressSuccess = (tickets) => {
    return {
        type: Types.GET_IN_PROGRESS_SUCCESS,
        payload: tickets
    }
}

export const getIsAwaiting = (filter) => {
    return {
        type: Types.GET_IS_AWAITING,
        payload: filter
    }
}

export const getIsAwaitingSuccess = (tickets) => {
    return {
        type: Types.GET_IS_AWAITING_SUCCESS,
        payload: tickets
    }
}

export const setLoadingMetrics = (isLoading) => {
    return {
        type: Types.SET_LOADING_METRICS,
        payload: isLoading
    }
}

export const setLoadingReports = (isLoading) => {
    return {
        type: Types.SET_LOADING_REPORTS,
        payload: isLoading
    }
}
