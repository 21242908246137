import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { list } from "../../services/Queue";
import { Types, listQueuesSuccess } from "../reducers/Queue"
import { errorMessage } from "../actions/FeedbackMessage"

export function* listQueuesSaga() {
    yield takeLatest(Types.LIST_QUEUE, function* () {
        try {
            const queues = yield call(list)
            yield put(listQueuesSuccess(queues))
        } catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(listQueuesSaga),
    ]);
}
