import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Queue {
    static async list() {
        try {
            const response = await Api.get('/queues');

            return response;
        } catch (err) {
            throw handleError(err);
        }
    }
}

export const list = async () => await Queue.list();

export default Queue;
